<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <photo-card
              :personalData="personalData"
              :stateList="stateList"
              :transforToAgent="toAgent"
            ></photo-card>
          </v-col>
          <v-col cols="12">
            <cards-info-panel
              :infoCards="infoCards"
              :routesConfigNoteCard="routesConfigNoteCard"
              :personalData="personalData"
              :emailRoutesConfig="emailRoutesConfig"
              :canEdit="canEdit"
              @getData="getData"
              @toggleDocumentsPanel="toggleDocumentsPanel"
            >
            </cards-info-panel>
          </v-col>
          <!-- Only show documents data panel if user is admin -->
          <v-col cols="12" v-if="isAdmin">
            <documents-data-panel
              :isOpenDocumentsPanel="isOpenDocumentsPanel"
              :idProspecto="this.$route.params.prospecto_id"
            ></documents-data-panel>
          </v-col>
          <v-col cols="12">
            <personal-data-panel
              :personalData="personalData"
              :stateList="stateList"
              :routesConfig="routesConfig"
              :canEdit="canEdit"
              @getData="getData"
            >
            </personal-data-panel>
          </v-col>
          <v-col cols="12">
            <contact-data-panel
              :contactData="contactData"
              :correosDataFormat="correosDataFormat"
              :routesContactData="routesContactData"
              :canEdit="canEdit"
              @getData="getData"
            >
            </contact-data-panel>
          </v-col>
          <v-col cols="12">
            <security-data-panel
              :personalData="personalData"
              :routesConfigPassword="routesConfigPassword"
              :canEdit="canEdit"
              @getData="getData"
            >
            </security-data-panel>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="2" lg="2" xl="2">
        <v-row>
          <v-col cols="12" v-if="screenWidth1 > 999">
            <profile-card :correo="personalData.correo"></profile-card>
          </v-col>
          <v-col cols="12" v-if="showToAgent">
            <ProfileToAgent :toAgentRoute="toAgentRoute"></ProfileToAgent>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import PhotoCard from "./PhotoCard.vue";
import PersonalDataPanel from "../components/personalData/PersonalDataPanel.vue";
import ContactDataPanel from "../components/contactData/ContactDataPanel.vue";
import SecurityDataPanel from "../components/securityData/SecurityDataPanel.vue";
// import ProfileCard from "../components/profileData/ProfileCard.vue";
// import ProfileToAgent from "../components/profileData/ProfileToAgent.vue";
import CardsInfoPanel from "../components/cardsInfo/CardsInfoPanel.vue";
import DocumentsDataPanel from "../components/documentsData/DocumentsDataPanel.vue";
import { mainAxios } from "@/mainAxios";
import moment from "moment";

export default {
  components: {
    PhotoCard,
    PersonalDataPanel,
    ContactDataPanel,
    SecurityDataPanel,
    // ProfileCard,
    CardsInfoPanel,
    // ProfileToAgent,
    DocumentsDataPanel,
  },

  data() {
    return {
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      personalData: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        sexo: "",
        rfc: "",
        fechaNacimiento: "",
        nacimientoEstadoId: "",
      },
      stateList: [],
      routesConfig: {
        completeData: false,
        route: "/v1/prospecto/perfil",
        action: "PUT",
      },
      routesConfigPassword: {
        route: "/v1/prospecto/perfil/password",
        action: "PUT",
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
      },
      contactData: {},
      routesConfigNoteCard: {
        route: "",
        action: "POST",
      },
      correosDataFormat: {
        correos: [],
        action: "POST",
      },
      routesContactData: {
        routeCommond: "/v1/prospecto/perfil",
        routeCommondAction: "PUT",
        deleteEmails: "/v1/correos-contacto/prospecto/delete",
        changeEmails: "/v1/correo-contacto/prospecto/", //query param idCorreo para modificar correo
        insertEmails: "/v1/correo-contacto/prospecto/insert",
      },
      toAgent: false,
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      screenWidth1: window.innerWidth,
      canEdit: true,
      showToAgent: false,
      toAgentRoute: "",
      isOpenDocumentsPanel: false,
      isAdmin: false,
    };
  },

  methods: {
    toggleDocumentsPanel() {
      this.isOpenDocumentsPanel = !this.isOpenDocumentsPanel;
    },
    getProspectData() {
      if (typeof this.$route.params.prospecto_id != "undefined") {
        const queryParams = {
          idProspecto: this.$route.params.prospecto_id,
        };
        this.config.params = queryParams;
        this.routesConfigNoteCard.route =
          "/v1/prospecto/documento/upload/" + this.$route.params.prospecto_id;
      } else {
        this.routesConfigNoteCard.route =
          "/v1/prospecto/documento/upload/" + localStorage.id;
      }
      mainAxios.get("/v1/prospecto/perfil", this.config).then((value) => {
        this.formatPersonalData(value.data.data.prospecto);
        this.formatContactData(value.data.data.prospecto);
        this.formatNotasDocumentos(value.data.data.prospecto.documentosCarga);
        this.formatCorreosInfoCard(value.data.data.prospecto.correosEnviados);
        if (typeof value.data.data.agente != "undefined") {
          this.toAgentRoute =
            "/usuario/ver-micuenta/" + value.data.data.agente.uuid;
          this.showToAgent = true;
        }
      });
      mainAxios
        .get("/v1/correos-contacto/prospecto/", this.config)
        .then((value) => {
          this.formatCorreos(value.data.data.correos);
        });
    },
    getStates() {
      mainAxios.get("/v1/catalogo/estado", this.config).then((value) => {
        this.stateList = value.data;
      });
    },
    formatPersonalData(data) {
      this.personalData = {
        idProspecto: data.id,
        nombre: data.nombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        sexo: parseInt(data.sexo),
        rfc: data.rfc,
        fechaNacimiento: data.fechaNacimiento,
        nacimientoEstadoId: String(data.nacimientoEstadoId),
        correo: data.correo,
        celular: data.celular,
        telefono: data.telefono,
        status: data.status,
      };
      if (data.status == "3") {
        this.canEdit = false;
      }
    },
    formatContactData(data) {
      this.contactData = {
        idProspecto: data.id,
        celular: data.celular,
        telefono: data.telefono,
      };
    },
    formatCorreos(correos) {
      this.correosDataFormat.correos = [];
      correos.map((c) => {
        this.correosDataFormat.correos.push({
          id: c.id,
          uuid: c.uuid,
          correo: c.correo,
          prospecto_id: c.prospecto_id,
          favorito: c.favorito,
        });
      });
      this.calculateRoutesEmails();
    },
    formatCorreosInfoCard(correos) {
      this.infoCards.emails = [];
      correos.map((e) => {
        this.infoCards.emails.push({
          showInfo:
            e.subject +
            +" " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY") +
            " " +
            e.subject.slice(0, 10) +
            " ... " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("HH:mm"),
          dateFormat: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm"),
          Onlyfecha: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY"),
          hora: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("HH:mm"),
          url_documento:
            e.documentos.length > 0 ? e.documentos[0].url_bucket : false,
          ...e,
        });
      });
      this.calculateRoutesEmails();
    },
    getData() {
      this.getProspectData();
    },
    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            url: e.url_bucket,
            ...e,
          });
        }
      });
      this.checkDocsToTransfer();
    },
    calculateRoutesEmails() {
      this.emailRoutesConfig.sendEmailRoute =
        "/v1/portal/prospectoagente/correo/enviar";
      this.emailRoutesConfig.action = "POST";
      this.emailRoutesConfig.optionCorreosList = this.correosDataFormat.correos;
      this.emailRoutesConfig.remitente = localStorage.agenteMail;
      this.emailRoutesConfig.prospectoId = this.personalData.idProspecto;
    },
    checkDocsToTransfer() {
      var toCompare = [
        "Cédula de Agente",
        "Comprobante de Domicilio",
        "Identificación oficial",
        "Documento de afiliación",
        "RC de Agente",
        "Caratula de estado de cuenta",
        "Constancia de situación fiscal",
      ];
      var i = 0;
      toCompare.forEach((e) => {
          let documentosTipo = this.infoCards.docs.filter((d) => d.tipoDocumento == e);
          let documentoValido = documentosTipo.find((d) => d.statusValid == 1);
          if (documentoValido) {
              i++;
          }
      });
      if (
        i == 7 &&
        (this.rol == "ADMIN" ||
          this.rol == "ADMINAGENT" ||
          this.rol == "RECLUTA") &&
        this.personalData.status == 1
      ) {
        this.$toast.info(
          "El prospecto tiene todos los documentos para convertirse en agente! ",
          {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        this.toAgent = true;
      }
    },
    updateScreenSize() {
      this.screenWidth1 = window.innerWidth;
    },
    //method to check if the user is admin
    checkIsAdmin() {
      const roles = JSON.parse(localStorage.getItem("roles")) || [];
      return roles.some((role) => role.rol === "ADMINAGENT");
    },
  },
  mounted() {
    this.personalData = Object.assign({
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      nacimientoEstadoId: 1,
    });
    this.isAdmin = this.checkIsAdmin();
    this.getProspectData();
    this.getStates();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
};
</script>
