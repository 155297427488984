<template>
  <div>
    <!-- Versión de escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <common-card>
        <v-card-text>
          <v-row style="padding-bottom: 15px; padding-top: 15px;">
            <!-- Bloque izquierdo: Avatar y nombre -->
            <v-col cols="8">
              <v-row>
                <v-col class="full-center-div" cols="3">
                  <v-avatar
                    color="primary"
                    style="height: 100px; min-width: 100px; cursor: pointer;"
                  >
                    <span class="white--text text-h5">
                      {{
                        (
                          name.split(" ")[0][0] +
                          (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                        ).toUpperCase()
                      }}
                    </span>
                  </v-avatar>
                </v-col>
                <v-col cols="9" style="padding-left: 0;">
                  <div class="text-h5 font-weight-bold" style="line-height: 6rem;">
                    {{
                      personalData.nombre +
                      " " +
                      personalData.apellidoPaterno +
                      " " +
                      personalData.apellidoMaterno
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <!-- Separador vertical -->
            <v-divider
              vertical
              style="
                border-color: #00a7e4;
                border-width: 2px;
                height: 103px;
                margin-top: 9px;
                margin-right: 8px;
              "
            ></v-divider>

            <!-- Bloque derecho: ID, Ubicación y Estatus -->
            <v-col cols="3">
              <div>
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">ID:</span> {{ personalData.idProspecto }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Ubicación:</span>
                  {{ getStateName(personalData.nacimientoEstadoId) }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Estatus:</span>
                  <span
                    v-if="canConvertAgent"
                    @click="handleConvertAgentClick"
                    style="color: #00a7e4; cursor: pointer; text-decoration: none; font-weight: bold;"
                  >
                    Convertir a agente
                  </span>
                  <span v-else style="color: #00a7e4; font-weight: bold;">
                    {{ getStatus(personalData.status) }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </common-card>
    </template>

    <!-- Versión móvil -->
    <template v-else>
      <common-card>
        <v-card-text>
          <v-row
            class="photo-card-mobile-layout"
            style="padding-bottom: 15px; padding-top: 15px;"
            align="center"
          >
            <!-- Avatar centrado -->
            <v-col cols="12" class="full-center-div text-center">
              <v-avatar
                color="primary"
                style="height: 100px; min-width: 100px; cursor: pointer;"
              >
                <span class="white--text text-h5">
                  {{
                    (
                      name.split(" ")[0][0] +
                      (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                    ).toUpperCase()
                  }}
                </span>
              </v-avatar>
            </v-col>

            <!-- Nombre debajo de la foto -->
            <v-col cols="12" class="text-center">
              <div class="text-h5 font-weight-bold" style="line-height: 2rem;">
                {{
                  personalData.nombre +
                  " " +
                  personalData.apellidoPaterno +
                  " " +
                  personalData.apellidoMaterno
                }}
              </div>
            </v-col>

            <!-- Información adicional: ID, Ubicación y Estatus -->
            <v-col cols="12" class="text-center">
              <div>
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">ID:</span> {{ personalData.idProspecto }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Ubicación:</span>
                  {{ getStateName(personalData.nacimientoEstadoId) }}
                </div>
              </div>
              <div class="mt-3">
                <div class="text-body-1 mb-1">
                  <span class="font-weight-bold">Estatus:</span>
                  <span
                    v-if="canConvertAgent"
                    @click="handleConvertAgentClick"
                    style="color: #00a7e4; cursor: pointer; text-decoration: none; font-weight: bold;"
                  >
                    Convertir a agente
                  </span>
                  <span v-else style="color: #00a7e4; font-weight: bold;">
                    {{ getStatus(personalData.status) }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </common-card>
    </template>

    <!-- Modal para crear agente -->
    <create-agent-modal
      :personalData="personalData"
      :transforToAgent="transforToAgent"
      :OpenCreateAgentModal="OpenCreateAgentModal"
      @closeModal="handleModalClose"
    />
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import CreateAgentModal from "@/components/commonComponents/dialogs/CreateAgentModal.vue";

export default {
  name: "PhotoCard",
  components: {
    CommonCard,
    CreateAgentModal,
  },
  props: {
    personalData: {
      type: Object,
      required: true,
    },
    stateList: {
      type: Array,
      default: () => [],
    },
    // Indica si se permite la conversión a agente
    transforToAgent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Se obtiene el nombre del agente o se asigna "AN" por defecto
      name: localStorage.agenteName || "AN",
      // Se obtiene el rol del usuario; si no se encuentra, se asigna "ADMIN" por defecto
      rol: JSON.parse(localStorage.getItem("roles") || '[{"rol":"ADMIN"}]')[0].rol,
      // Bandera para controlar la visibilidad del modal
      OpenCreateAgentModal: false,
    };
  },
  computed: {
    // Se respeta la lógica para determinar si se muestra "Convertir a agente"
    canConvertAgent() {
      return (
        (this.rol === "ADMIN" ||
          this.rol === "ADMINAGENT" ||
          this.rol === "RECLUTA") &&
        this.transforToAgent &&
        this.personalData.status == 1
      );
    },
  },
  methods: {
    getStateName(id) {
      if (this.stateList.length > 0) {
        const state = this.stateList.find((e) => e.cEstado == id);
        return state ? state.dEstado : "No disponible";
      }
      return "No disponible";
    },
    // Se respeta la lógica del snippet: si existe prospecto_id en la ruta, se mapea el status según sus valores
    getStatus(status) {
      if (typeof this.$route.params.prospecto_id !== "undefined") {
        switch (status) {
          case "1":
            return "Nuevo";
          case "2":
            return "Prospección";
          case "3":
            return "Convertido";
          case "4":
            return "Rechazado";
          case "5":
            return "Declinado";
          default:
            return "Nuevo";
        }
      } else {
        return "Nuevo";
      }
    },
    handleConvertAgentClick() {
      this.OpenCreateAgentModal = true;
    },
    handleModalClose() {
      this.OpenCreateAgentModal = false;
    },
  },
};
</script>

<style scoped>
.full-center-div {
  align-self: center;
  text-align: center;
}

@media (max-width: 959px) {
  /* Estilo específico para móvil */
  .photo-card-mobile-layout {
    flex-direction: column !important;
  }
  .photo-card-mobile-layout .v-col {
    margin-bottom: 10px;
  }
  .photo-card-mobile-layout .v-avatar {
    margin: 0 auto;
  }
}
</style>


