<template>
  <div>
    <CommonExpansionPanel :currentPanel="currentPanel">
      <template v-slot:title-panel>
        <div class="title-panel">
          Documentos
        </div>
      </template>
      <v-expansion-panel-content>
        <!-- Check if there are documents to display -->
        <template v-if="prospectDocuments.length">
          <v-data-table
            :headers="headers"
            :items="prospectDocuments"
            class="elevation-0"
            hide-default-footer
          >
          <!-- Display document name -->
            <template v-slot:item.name="{ item }">
              <span class="documento-nombre">{{ item.name }}</span>
            </template>
            <!-- Download button -->
            <template v-slot:item.download="{ item }">
              <v-btn icon @click="download(item)">
                <v-icon color="blue">mdi-download</v-icon>
              </v-btn>
            </template>
            <!-- Preview button -->
            <template v-slot:item.visualize="{ item }">
              <v-btn icon @click="visualize(item)">
                <v-icon color="blue">mdi-eye</v-icon>
              </v-btn>
            </template>
            <!-- Status selector -->
            <template v-slot:item.status="{ item: rowItem }">
              <div class="d-flex justify-center align-center">
                <v-select
                  v-model="rowItem.status"
                  :items="statusOptions"
                  :ripple="false"
                  dense
                  hide-selected
                  outlined
                  type="text"
                  color="inherit"
                  hide-details
                  class="estatus-select"
                  :style="{
                    backgroundColor: getStatusStyle(rowItem.status)
                      .backgroundColor,
                  }"
                  @change="updateDocumentStatus(rowItem)"
                >
                <!-- Selected status display -->
                  <template v-slot:selection="{ item: selectedOption }">
                      {{ selectedOption.text }}
                  </template>
                  <!-- Status dropdown options with icons -->
                  <template v-slot:item="{ item: option }">
                    <div class="d-flex" style="gap:8px">
                      <v-list-item-icon class="align-self-center ma-0">
                        <v-icon :color="getStatusStyle(option.value).color">{{
                          getStatusStyle(option.value).icon
                        }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="status-text">{{
                          option.text
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </div>
                  </template>
                </v-select>
              </div>
            </template>
          </v-data-table>
        </template>
        <!-- If there are no documents to display -->
        <template v-else>
          <div class="d-flex flex-column align-center justify-center">
            <v-icon size="48" color="grey">mdi-file-document-outline</v-icon>
            <p>El prospecto no ha cargado ningún documento aún</p>
          </div>
        </template>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
    <!-- Preview modal for documents -->
    <v-dialog v-model="previewDialog" max-width="600"> 
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
        <div v-if="previewFile">
          <!-- Si es imagen, usamos <v-img> -->
          <v-img v-if="previewFile.type.includes('image')" :src="previewUrl" max-height="400" />
          <!-- Caso contrario (normalmente PDF), usamos <iframe> en desktop y descarga en mobile -->
          <template v-else>
            <iframe v-if="!$vuetify.breakpoint.smAndDown" :src="previewUrl" width="100%" height="400" style="border: none"></iframe>
            <v-btn v-else :href="previewUrl" target="_blank" color="primary" class="d-flex justify-center">Ver archivo</v-btn>
          </template>
        </div>
          </v-card-text>
          <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closePreview">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- Loading overlay -->
    <v-overlay :value="loading" opacity="0.1">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
    <!-- Snackbar for status updates -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
      <v-btn text @click="snackbar.show = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { STATUS_OPTIONS, DOCUMENT_TYPE_MAP } from "./utils/constants";
import { mainAxios } from "@/mainAxios.js";
export default {
  name: "DocumentsDataPanel",
  components: {
    CommonExpansionPanel,
  },
  props: {
    isOpenDocumentsPanel: Boolean,
    idProspecto: String,
  },
  data() {
    return {
      prospectDocuments: [],
      currentPanel: null,
      headers: [
        { text: "Lista", value: "name", sortable: false },
        {
          text: "Descargar",
          value: "download",
          sortable: false,
          align: "center",
        },
        {
          text: "Visualizar",
          value: "visualize",
          sortable: false,
          align: "center",
        },
        { text: "Estatus", value: "status", sortable: false, align: "center" },
      ],

      statusOptions: STATUS_OPTIONS.map(({ text, value, id }) => ({
        text,
        value,
        id,
      })),
      STATUS_STYLES: {
        "por validar": {
          color: "orange",
          backgroundColor: "#FFB600",
          icon: "mdi-progress-clock",
        },
        validado: {
          color: "green",
          backgroundColor: "#379E1A",
          icon: "mdi-check-circle-outline",
        },
        rechazado: {
          color: "red",
          backgroundColor: "#FF3048",
          icon: "mdi-close-circle-outline",
        },
        default: {
          color: "gray",
          backgroundColor: "gray",
          icon: "mdi-help-circle",
        },
      },
      previewFile: null,
      previewUrl: null,
      previewDialog: false,
      loading: false,
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
    };
  },
  methods: {
    // Toggle documents panel
    toggleDocumentsPanel() {
      this.currentPanel = this.currentPanel === null ? 0 : null;
    },
    // Guess mime type based on file extension
    guessMimeType(filename) {
      const extension = filename
        .split(".")
        .pop()
        .toLowerCase();
      switch (extension) {
        case "pdf":
          return "application/pdf";
        case "jpg":
        case "jpeg":
          return "image/jpeg";
        case "png":
          return "image/png";
        default:
          return null;
      }
    },

    // Download document
    async download(item) {
      if (!item.document || !item.document.urlBucket) {
        console.error("there is no document to download");
        return;
      }
      try {
        const response = await fetch(item.document.urlBucket);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.document.nombre}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading document", error);
      }
    },
    // Preview document
    async visualize(item) {
      if (!item || !item.document) return;
      // File data to be used in the preview
      const fileData = {
        name: item.document?.nombre,
        url: item.document?.urlBucket,
        type: item.document?.contentType || "",
      };

      // If the file has a URL, we fetch it and create a blob
      if (fileData.url) {
        try {
          const response = await mainAxios.get(fileData.url, {
            responseType: "blob",
          });
          let mimeType = response.headers["content-type"] || fileData.type;

         // If the mime type is not provided, we try to guess it
          if (mimeType === "application/octet-stream") {
            mimeType = this.guessMimeType(fileData.name) || "application/pdf";
          }

          // Create a file from the blob
          const blob = new Blob([response.data], { type: mimeType });
          const backendFile = new File([blob], fileData.name, {
            type: mimeType,
          });

          // Set the file and URL for the preview
          this.previewFile = backendFile;
          this.previewUrl = URL.createObjectURL(backendFile);
          this.previewDialog = true;
        } catch (err) {
          console.error("Error obteniendo el archivo:", err);
        }
      } else {
        // If the file does not have a URL, we set the file and URL for the preview
        this.previewFile = fileData;
        this.previewUrl = URL.createObjectURL(fileData);
        this.previewDialog = true;
      }
    },
    // Close preview modal
    closePreview() {
      this.previewDialog = false;
      if (this.previewFile) {
        URL.revokeObjectURL(this.previewUrl);
      }
      this.previewFile = null;
    },
    // Update document status
    async updateDocumentStatus(item) {
      const numericStatus = STATUS_OPTIONS.find(
        (opt) => opt.value === item.status
      )?.id;
      if (numericStatus === undefined) {
        console.error("Invalid status value");
        return;
      }

      const url = `/v1/prospecto/documentosCargados/${item.uuid}/${numericStatus}`;
      try {
        this.loading = true;

        await mainAxios.put(
          url,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        this.snackbar = {
          show: true,
          text: "Estatus actualizado correctamente",
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          show: true,
          text: "Hubo un error al actualizar el estatus",
          color: "error",
        };
        console.error("Error updating document status", error);
      } finally {
        this.loading = false;
      }
    },
    // Get status style
    getStatusStyle(status) {
      return this.STATUS_STYLES[status] || this.STATUS_STYLES.default;
    },
    // Map documents to display
    mapDocuments(apiData) {
      return DOCUMENT_TYPE_MAP.map(({ label, type }) => {
        const existingDoc = apiData.find(
          (p) =>
            p.tipoDocumento.includes(type) &&
            Array.isArray(p.documentos) &&
            p.documentos.length > 0
        );

        return {
          name: label,
          uuid: existingDoc ? existingDoc.uuid : null,
          document: existingDoc ? existingDoc.documentos[0] : null,
          status: existingDoc
            ? STATUS_OPTIONS.find((s) => s.id === existingDoc.statusValid)
                .value || "por validar"
            : "por validar",
        };
      }).filter((doc) => doc.document !== null && doc.status !== "rechazado");
    },
    // Fetch documents from API
    async fetchDocuments() {
      const url = `/v1/prospecto/documentosCargados/${this.idProspecto}`;
      try {
        const res = await mainAxios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        });

        const mappedResponse = res.data.map(
          ({ tipoDocumento, documentos, statusValid, uuid }) => ({
            tipoDocumento,
            documentos,
            statusValid,
            uuid,
          })
        );

        this.prospectDocuments = this.mapDocuments(mappedResponse);
      } catch (error) {
        console.error("error trying to get documents", error);
      }
    },
  },
  watch: {
    // Watch for changes in the documents panel
    isOpenDocumentsPanel(val) {
      this.currentPanel = val ? 0 : null;
    },
  },
  mounted() {
    this.fetchDocuments();
  },
};
</script>

<style scoped>
/deep/ .v-data-table-header th {
  font-size: 15px !important;
  font-family: "Poppins", sans-serif;
  line-height: 20px;
  font-weight: 400;
}

/deep/ .v-input__slot {
  background: transparent !important;
}

/deep/ .v-expansion-panel--active .title-panel {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  color: #586268;
}

.documento-nombre {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #303030;
}

.estatus-select {
  max-width: 140px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  color: #475467;
}

.status-text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: #182230;
}

/deep/ .text-start {
  border-bottom: none !important;
}

/deep/ .text-center {
  border-bottom: none !important;
}

/deep/ .v-text-field--outlined fieldset {
  border: none !important;
}

/deep/ .v-select .v-select__selections input {
  display: none;
}
</style>
