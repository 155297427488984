//constant to show the status of the document
export const STATUS_OPTIONS = [
  { id: 0, text: "Por validar", value: "por validar" },
  { id: 1, text: "Validado", value: "validado" },
  { id: 2, text: "Rechazado", value: "rechazado" },
];

//constant to differentiate the type of document between back and front names.
// label is the name that is shown to the user
// type is the name that is obtained from the backend
export const DOCUMENT_TYPE_MAP = [
  {
    label: "Identificación: INE o pasaporte (vigente).",
    type: "Identificación oficial",
  },
  {
    label: "Constancia de Situación Fiscal.",
    type: "Constancia de situación fiscal",
  },
  {
    label: "Comprobante de Domicilio: No mayor a 3 meses",
    type: "Comprobante de Domicilio",
  },
  {
    label: "Carátula de Cuenta Bancaria: No mayor a 3 meses",
    type: "Caratula de estado de cuenta",
  },
  {
    label: "Cédula de Agente de Seguros (opcional)",
    type: "Cédula de Agente",
  },
  {
    label: "RC de Agente (opcional)",
    type: "RC de Agente",
  },
  {
    label: "Documento de afiliación",
    type: "Documento de afiliación",
  },
];
